/* eslint-disable @typescript-eslint/no-explicit-any */
import { CartProvider, SessionProvider, UIProvider } from '@faststore/sdk'
import {
  AppInsightsContext,
  AppInsightsErrorBoundary,
} from '@microsoft/applicationinsights-react-js'
import type { GatsbyBrowser } from 'gatsby'
import Layout from 'src/Layout'
import LayoutFullscreen from 'src/LayoutFullscreen'
import { RenderClient } from 'src/components/RenderClient'
import ClerkioScript from 'src/components/ThirdPartyScripts/ClerkioScript'
import SharedCartModal from 'src/components/cart/SharedCartModal'
import { SharedCartProvider } from 'src/contexts/shared-cart-context'
import { WishlistProvider } from 'src/contexts/wishlist-context'
import AnalyticsHandler from 'src/sdk/analytics'
import { validateSharedCart } from 'src/sdk/cart/validate'
import ErrorBoundary from 'src/sdk/error/ErrorBoundary'
import { validateSession } from 'src/sdk/session/validate'
import { uiActions, uiEffects, uiInitialState } from 'src/sdk/ui'
import { ModalProvider } from 'src/sdk/ui/modal'
import './src/styles/global.css'
import './src/styles/global/layout.scss'
import './src/styles/global/resets.scss'
import './src/styles/global/tokens.scss'
import './src/styles/global/typography.scss'

import { Helmet } from 'react-helmet'
import AbandonedCartEventHandler from 'src/components/cart/AbandonedCartEventHandler'
import { PageTypeProvider } from 'src/contexts/page-type-context'
import { StoreProvider } from 'src/contexts/store-context'
import { UserAccountProvider } from 'src/contexts/user-account-context'
import { ApplicationInsightsService } from 'src/sdk/insights/ApplicationInsightsService'
import { getPageType } from 'src/utils/getPageType'
import storeConfig from './store.config'

export const wrapRootElement: GatsbyBrowser['wrapRootElement'] = ({
  element,
}) => (
  <ErrorBoundary>
    <AnalyticsHandler />
    <UIProvider
      initialState={uiInitialState}
      actions={uiActions}
      effects={uiEffects}
    >
      <SessionProvider
        initialState={{
          channel: storeConfig.session.channel,
          locale: storeConfig.session.locale,
          currency: storeConfig.session.currency,
          country: storeConfig.session.country,
        }}
        onValidateSession={validateSession}
      >
        <CartProvider mode="optimistic" onValidateCart={validateSharedCart}>
          <SharedCartProvider>
            <ModalProvider>
              <StoreProvider>
                <UserAccountProvider>
                  <AbandonedCartEventHandler>
                    {element}
                  </AbandonedCartEventHandler>
                  <RenderClient>
                    <SharedCartModal />
                  </RenderClient>
                </UserAccountProvider>
              </StoreProvider>
            </ModalProvider>
          </SharedCartProvider>
        </CartProvider>
      </SessionProvider>
    </UIProvider>
  </ErrorBoundary>
)

export const wrapPageElement: GatsbyBrowser['wrapPageElement'] = ({
  element,
  props,
}) => {
  const appInsightService = ApplicationInsightsService(
    getPageType(props?.pageResources?.page?.componentChunkName)
  )
  // Check if the page is Institutional for mobile app
  const isInstitutionalForApp = props?.pageContext?.isInstitutionalForApp
  // If it is, return the element without the layout
  if (isInstitutionalForApp) {
    return (
      <>
        {element}
        <RenderClient>
          <ClerkioScript />
        </RenderClient>
      </>
    )
  }

  const node = props?.data?.cmsFlyerMobile

  if (node) {
    // @ts-expect-error config throw error
    if (node?.config?.slugAndFilterConfig?.noLayout) {
      return <>{element}</>
    }
  }
  const nodeFull = props?.data?.cmsFlyerFullScreen

  if (nodeFull) {
    // @ts-expect-error config throw error
    if (nodeFull?.config?.slugAndFilterConfig?.noLayout) {
      return <LayoutFullscreen>{element}</LayoutFullscreen>
    }
  }

  const nodeBooxieMobilePage = props?.data?.cmsBooxieMobilePage
  console.log('BROWSER - nodeBooxieMobilePage: ', nodeBooxieMobilePage)
  if (nodeBooxieMobilePage) {
    const externalScriptUrls: string[] =
      // @ts-expect-error config throw error
      nodeBooxieMobilePage.config?.slugAndFilterConfig?.externalScripts ?? []
    console.log('externalScriptUrls: ', externalScriptUrls)
    // @ts-expect-error config throw error
    if (nodeBooxieMobilePage?.config?.slugAndFilterConfig?.noLayout) {
      return (
        <>
          {externalScriptUrls.length &&
            externalScriptUrls.map((scriptSrc: string) => (
              <Helmet>
                <script src={scriptSrc}></script>
              </Helmet>
            ))}
          {element}
        </>
      )
    } else {
      return (
        <>
          {externalScriptUrls.length &&
            externalScriptUrls.map((scriptSrc: string) => (
              <Helmet>
                <script src={scriptSrc}></script>
              </Helmet>
            ))}
          <Layout>{element}</Layout>
        </>
      )
    }
  }

  return (
    <AppInsightsErrorBoundary
      onError={() => <h1>I believe something went wrong</h1>}
      appInsights={appInsightService.reactPlugin}
    >
      <AppInsightsContext.Provider value={appInsightService.reactPlugin}>
        <PageTypeProvider
          pageType={getPageType(props?.pageResources?.page?.componentChunkName)}
        >
          <WishlistProvider>
            <Layout
              pageType={getPageType(
                props?.pageResources?.page?.componentChunkName
              )}
            >
              {element}
              <RenderClient>
                <ClerkioScript />
              </RenderClient>
            </Layout>
          </WishlistProvider>
        </PageTypeProvider>
      </AppInsightsContext.Provider>
    </AppInsightsErrorBoundary>
  )
}

export const onInitialClientRender = () => {
  // Signal that the app is ready for Cypress tests
  document.body.dataset.testReady = 'true'
}
